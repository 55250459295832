<template>
<main>

  <div class="container-fluid wrapper-small content-form">
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <h1 class="mb-3 anim-load1">
            Register interest
          </h1>
          <p class="lead anim-load2">
            Enter your details below and a member of our team will get in touch with you to discuss the details.
          </p>
        </div>
        <div class="card mt-3 mt-md-0 ml-md-5 mr-md-5">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submit" class="mb-3">

                <div class="form-group" ref="title">
                  <label for="titleInput">Title</label>
                  <ValidationProvider name="titleInput" rules='required' v-slot="{errors}">
                      <!-- <select v-model="form.title" class="form-control" v-bind:class="classes">
                        <option v-for="option in titleOptions" v-bind:value="option.value" v-bind:key="option.value">
                          {{ option.text }}
                        </option>
                      </select> -->
                      <v-select
                        :clearable="false"
                        :options="titleOptions"
                        label="value"
                        v-model="form.title"
                        :reduce="item => item.value"
                        placeholder="Select"
                        class="form-control"
                        :class="{'is-invalid': !!errors.length}"
                      ></v-select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="firstName">
                  <label for="firstNameInput">First name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="firstName">
                    <input v-model="form.firstName" type="text" placeholder="" name="firstNameInput" class="form-control" v-bind:class="classes" autocomplete="given-name">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="lastName">
                  <label for="lastNameInput">Last name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="lastName">
                    <input v-model="form.lastName" type="text" placeholder="" name="lastNameInput" class="form-control" v-bind:class="classes" autocomplete="family-name">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="organisation">
                  <label for="organisationInput">Organisation</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="organisation">
                    <input v-model="form.organisation" type="text" placeholder="" name="organisationInput" class="form-control" v-bind:class="classes" autocomplete="organization">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="email">
                  <label for="emailInput">Email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                    <input ref="emailInputRef" v-model="form.email" type="text" placeholder="" name="emailInput" class="form-control" v-bind:class="classes" autocomplete="email">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group mt-3" ref="phone">
                  <label for="phone">Your phone number</label>
                  <phone-input-field v-model="form.phone" />
                </div>

                <div class="form-group" ref="terms">
                  <ValidationProvider v-slot="{classes, errors}" :rules="{ required: { allowFalse: false } }" name="Terms and Conditions">
                    <div class="custom-control custom-checkbox custom-control-large" v-bind:class="classes">
                      <input type="checkbox" class="custom-control-input" id="terms" :value="true" v-model="form.terms">
                      <label class="custom-control-label" for="terms"><p class="mb-0">I agree to the <b-link target="_blank" to="/privacy-policy" class="link">Privacy Policy</b-link> and <b-link target="_blank" to="/terms-of-use" class="link">Terms of use</b-link></p></label>
                    </div>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>

                <button type="submit" class="btn btn-warning btn-lg btn-block mt-4">Submit</button>
              </b-form>
            </ValidationObserver>       
          </div>
        </div>
        <div class="text-center mt-3">
          <p>Already have an account? <b-link class="btn-link" to="/sign-in">Sign in here</b-link></p>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</main>
</template>

<script>
import PhoneInputField from '@/components/modules/PhoneInputField.vue'

export default {
  name: 'RegisterInterest',
  components: {
    PhoneInputField,
  },
  data () {
    return {
      form: {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        organisation: '',
        terms: false
      },
      serverError: '',
      showDismissibleAlert: false,
      titleOptions: this.$uiValues.titleOptions,
    }
  },
  async created () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  },
  methods: {
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          // Validate phone number
          if (this.form.phone?.valid !== true) {
            this.serverError = "Phone number is not valid";
            this.showDismissibleAlert = true;
          } else {
            this.showDismissibleAlert = false
            this.$store.state.loadingProgress = true
            this.$http({
              method: 'post', 
              url: this.$api.RegisterInterest,
              data: {
                ...this.form,
                phone: this.form.phone.number
              },
              headers: {'Authorization': 'bearer ' + this.$store.state.jwt }
            })
              .then((response) => {
                console.log(response)
                this.$router.push('/interest-registered')
              })
              .catch((error) => {
                console.error('Error', error)
                this.alertMsgShow('Something went wrong', false);
              })
              .then(() => {
                this.$store.state.loadingProgress = false
              })
          }
        }
      });
    }
  }
}
</script>
