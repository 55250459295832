var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"container-fluid wrapper-small content-form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"card mt-3 mt-md-0 ml-md-5 mr-md-5"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"form"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{ref:"title",staticClass:"form-group"},[_c('label',{attrs:{"for":"titleInput"}},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"titleInput","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"clearable":false,"options":_vm.titleOptions,"label":"value","reduce":function (item) { return item.value; },"placeholder":"Select"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"firstName",staticClass:"form-group"},[_c('label',{attrs:{"for":"firstNameInput"}},[_vm._v("First name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"firstNameInput","autocomplete":"given-name"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"lastName",staticClass:"form-group"},[_c('label',{attrs:{"for":"lastNameInput"}},[_vm._v("Last name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"lastNameInput","autocomplete":"family-name"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"organisation",staticClass:"form-group"},[_c('label',{attrs:{"for":"organisationInput"}},[_vm._v("Organisation")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"organisation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.organisation),expression:"form.organisation"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"organisationInput","autocomplete":"organization"},domProps:{"value":(_vm.form.organisation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "organisation", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"email",staticClass:"form-group"},[_c('label',{attrs:{"for":"emailInput"}},[_vm._v("Email address")]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],ref:"emailInputRef",staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"emailInput","autocomplete":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"phone",staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Your phone number")]),_c('phone-input-field',{model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{ref:"terms",staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } },"name":"Terms and Conditions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox custom-control-large",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.terms),expression:"form.terms"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"terms"},domProps:{"value":true,"checked":Array.isArray(_vm.form.terms)?_vm._i(_vm.form.terms,true)>-1:(_vm.form.terms)},on:{"change":function($event){var $$a=_vm.form.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "terms", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "terms", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "terms", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"terms"}},[_c('p',{staticClass:"mb-0"},[_vm._v("I agree to the "),_c('b-link',{staticClass:"link",attrs:{"target":"_blank","to":"/privacy-policy"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('b-link',{staticClass:"link",attrs:{"target":"_blank","to":"/terms-of-use"}},[_vm._v("Terms of use")])],1)])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.showDismissibleAlert},on:{"dismissed":function($event){_vm.showDismissibleAlert=false}}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]),_c('button',{staticClass:"btn btn-warning btn-lg btn-block mt-4",attrs:{"type":"submit"}},[_vm._v("Submit")])],1)],1)],1)]),_c('div',{staticClass:"text-center mt-3"},[_c('p',[_vm._v("Already have an account? "),_c('b-link',{staticClass:"btn-link",attrs:{"to":"/sign-in"}},[_vm._v("Sign in here")])],1)])])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mb-4"},[_c('h1',{staticClass:"mb-3 anim-load1"},[_vm._v(" Register interest ")]),_c('p',{staticClass:"lead anim-load2"},[_vm._v(" Enter your details below and a member of our team will get in touch with you to discuss the details. ")])])}]

export { render, staticRenderFns }